import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { EmojiObjectsOutlined, Info } from '@mui/icons-material';
import { Indicator, IndicatorPackageTheme } from '../../../types/indicator';
import IndicatorTableRow from './IndicatorTableRow';
import IndicatorPackageThemeItemTable from './IndicatorPackageThemeItemTable';
import useStore from '../../../store/storeContext';

interface Props {
    theme: IndicatorPackageTheme;
    editing?: boolean;
    toggleEdit?(isEditingActive: boolean): void;
    isPreview: boolean;
    sharedData: string;
    isApproved: boolean;
}

function IndicatorPackageThemeItem(props: Props) {
    const context = useStore();
    const { dialog, localization, theming } = context;
    const { translate } = localization;
    const { theme, editing, toggleEdit, isPreview, isApproved } = props;

    const indicatorList = getIndicatorList();
    const themeTitleStyle = getThemeTitleStyle();

    function getIndicatorList(): Indicator[] {
        const { theme } = props;
        const allIndicators = [...theme.sotkanetIndicators, ...theme.organizationIndicators, ...theme.otherIndicators];
        const enabled = allIndicators.filter((indicator) => indicator.enabled);
        return context.indicator.sortByOrderNumber(enabled);
    }

    function getThemeTitleStyle(): React.CSSProperties | undefined {
        const { theme } = props;
        if (theme.level === 1 && !isPreview && !isApproved) return { cursor: 'pointer' };
        return undefined;
    }

    const handleThemeClick = (): void => {
        // Open edit indicator list only if document is not in preview mode and is not approved
        if (!isPreview && !isApproved) {
            const indicatorPackageTheme = props.theme;
            if (indicatorPackageTheme.level === 0) return;

            dialog.openDocumentDialog('editIndicatorListDialog', { indicatorPackageTheme });
        }
    };

    const mainLevel = theme.level === 0;

    return (
        <Box mt={mainLevel ? 4 : 0} p={2} pl={mainLevel ? 2 : 6} style={{ backgroundColor: theming.shvkBlue10 }}>
            <Box display="flex" alignItems="center">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" onClick={handleThemeClick} style={themeTitleStyle}>
                            {theme.icon && (
                                <Box mr={2}>
                                    <img src={`../images/${theme.icon}`} alt={theme.name} />
                                </Box>
                            )}
                            <Typography variant={mainLevel ? 'h5' : 'h6'}>
                                {theme.name}
                                {theme.info && (
                                    <Tooltip
                                        placement={'top'}
                                        style={{
                                            marginLeft: theming.spacing(0.5),
                                            verticalAlign: 'middle',
                                            paddingBottom: '2px',
                                        }}
                                        title={<span style={{ whiteSpace: 'pre-line' }}>{theme.info}</span>}
                                    >
                                        <Info color="primary" fontSize={mainLevel ? 'inherit' : 'small'} />
                                    </Tooltip>
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                    {!mainLevel && (
                        <>
                            {!isPreview && !isApproved && (
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <Box mr={1}>
                                            <EmojiObjectsOutlined />
                                        </Box>
                                        <Typography variant="caption">{translate('EDIT_INIDCATORS_TIP')}</Typography>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {indicatorList.length > 0 && (
                                    <IndicatorPackageThemeItemTable>
                                        {indicatorList.map((indicator) => (
                                            <IndicatorTableRow
                                                key={indicator.id}
                                                indicator={indicator}
                                                packageId={theme.pkgId}
                                                editing={editing}
                                                toggleEdit={toggleEdit}
                                            />
                                        ))}
                                    </IndicatorPackageThemeItemTable>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </Box>
    );
}

export default observer(IndicatorPackageThemeItem);
