import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    AddImpactOnTargetIndicatorParams,
    AddIndicatorDialogIndicator,
    AddIndicatorParams,
} from '../../../types/indicator';
import EditChapterDialog from './EditChapterDialog';
import AddDecisionOptionDialog from './eva/AddDecisionOptionDialog';
import EvaIndicatorListDialog from './eva/EvaIndicatorListDialog';
import EditImpactOnTargetNameDialog from './eva/EditImpactOnTargetNameDialog';
import AddImpactOnTargetDialog from './eva/AddImpactOnTargetDialog';
import AddPerspectiveDialog from './eva/AddPerspectiveDialog';
import ImpactOnTargetDialog from './eva/ImpactOnTargetDialog';
import DownloadPdfOrWordDialog from './DownloadPdfOrWordDialog';
import DownloadPowerPointDialog from './shvk/DownloadPowerPointDialog';
import HistoryDialog from './HistoryDialog';
import ShareDialog from './ShareDialog';
import PreviousSharesDialog from './PreviousSharesDialog';
import UsersDialog from './UsersDialog';
import ChangeOwnerDialog from './ChangeOwnerDialog';
import ApprovalDialog from './ApprovalDialog';
import DisapprovalDialog from './DisapprovalDialog';
import EvaDecisionOptionCompareDialog from './eva/EvaDecisionOptionCompareDialog';
import AddIndicatorDialog from './AddIndicatorDialog';
import EditIndicatorListDialog from './shvk/EditIndicatorListDialog';
import IndicatorGraphDialog from './IndicatorGraphDialog';
import IndicatorDescriptionDialog from './shvk/IndicatorDescriptionDialog';
import IndicatorWithNoteDialog from './shvk/IndicatorWithNoteDialog';
import ComparisonSettingsDialog from './shvk/ComparisonSettingsDialog';
import ChapterModelDialog from './ChapterModelDialog';
import EditEvaModelDialog from './eva/EditEvaModelDialog';
import IndicatorModelDialog from './shvk/IndicatorModelDialog';
import FocusAreaModelDialog from './shvk/FocusAreaModelDialog';
import UploadCustomIndicatorDialog from './shvk/UploadCustomIndicatorDialog';
import AddFocusAreaDialog from './shvk/AddFocusAreaDialog';
import AddFocusAreaGoalDialog from './shvk/AddFocusAreaGoalDialog';
import AddFocusAreaActionDialog from './shvk/AddFocusAreaActionDialog';
import CoverPageDialog from './CoverPageDialog';
import CrossOrganizationDialog from './shvk/CrossOrganizationDialog';
import useStore from '../../../store/storeContext';

function DocumentDialogs() {
    const { document, indicator, dialog, snackbar } = useStore();

    async function saveIndicators(indicators: AddIndicatorDialogIndicator[]): Promise<void> {
        const documentDialogList = dialog.documentDialogList;
        const impactOnTarget = documentDialogList.addIndicatorDialog.props?.impactOnTarget;
        const indicatorPackageTheme = documentDialogList.addIndicatorDialog.props?.indicatorPackageTheme;

        try {
            if (impactOnTarget) {
                // Adding indicators to eva
                const params: AddImpactOnTargetIndicatorParams = {
                    impactId: impactOnTarget.id,
                    indicators: indicators.map((indicator) => {
                        return {
                            indicatorId: indicator.id,
                            source: indicator.source,
                        };
                    }),
                };
                await indicator.addImpactOnTargetIndicator(params);
            } else if (indicatorPackageTheme) {
                // Adding yearly report indicator
                const params: AddIndicatorParams = {
                    documentId: document.currentDocument.id,
                    indicators: indicators.map((indicator) => {
                        return {
                            indicatorId: indicator.id,
                            source: indicator.source,
                            direction: indicator.direction,
                        };
                    }),
                    theme: indicatorPackageTheme,
                    themeId: indicatorPackageTheme.id,
                };
                await indicator.addIndicator(params);
            }
            snackbar.showSuccess();
        } catch (e) {
            snackbar.showError(e.data?.code);
        }
    }

    const { closeDocumentDialog, documentDialogList } = dialog;

    return (
        <>
            <DownloadPdfOrWordDialog
                isOpen={documentDialogList.downloadPdfDialog.open}
                close={(): void => closeDocumentDialog('downloadPdfDialog')}
                type="pdf"
            />
            <DownloadPdfOrWordDialog
                isOpen={documentDialogList.downloadWordDialog.open}
                close={(): void => closeDocumentDialog('downloadWordDialog')}
                type="word"
            />
            <DownloadPowerPointDialog
                isOpen={documentDialogList.downloadPowerPointDialog.open}
                close={(): void => closeDocumentDialog('downloadPowerPointDialog')}
            />
            <ShareDialog
                isOpen={documentDialogList.shareDialog.open}
                close={(): void => closeDocumentDialog('shareDialog')}
            />
            <PreviousSharesDialog
                isOpen={documentDialogList.previousSharesDialog.open}
                close={(): void => closeDocumentDialog('previousSharesDialog')}
            />
            <HistoryDialog
                isOpen={documentDialogList.historyDialog.open}
                close={(): void => closeDocumentDialog('historyDialog')}
            />
            <UsersDialog
                isOpen={documentDialogList.usersDialog.open}
                close={(): void => closeDocumentDialog('usersDialog')}
            />
            <ChangeOwnerDialog
                isOpen={documentDialogList.changeOwnerDialog.open}
                close={(): void => closeDocumentDialog('changeOwnerDialog')}
            />
            <ChapterModelDialog
                isOpen={documentDialogList.chapterModelDialog.open}
                close={(): void => {
                    void document.releaseChapterLocks();
                    closeDocumentDialog('chapterModelDialog');
                }}
            />
            <CoverPageDialog
                isOpen={documentDialogList.coverPageDialog.open}
                close={(): void => closeDocumentDialog('coverPageDialog')}
            />
            <ApprovalDialog
                isOpen={documentDialogList.approvalDialog.open}
                close={(): void => closeDocumentDialog('approvalDialog')}
            />
            <DisapprovalDialog
                isOpen={documentDialogList.disapprovalDialog.open}
                close={(): void => closeDocumentDialog('disapprovalDialog')}
            />
            <EditChapterDialog
                chapter={documentDialogList.editChapterDialog.props?.chapter}
                isOpen={documentDialogList.editChapterDialog.open}
                close={(): void => closeDocumentDialog('editChapterDialog')}
            />
            <AddIndicatorDialog
                isOpen={documentDialogList.addIndicatorDialog.open}
                close={(): void => closeDocumentDialog('addIndicatorDialog')}
                impactOnTarget={documentDialogList.addIndicatorDialog.props?.impactOnTarget}
                indicatorPackageTheme={documentDialogList.addIndicatorDialog.props?.indicatorPackageTheme}
                saveIndicators={saveIndicators}
            />
            <IndicatorGraphDialog
                isOpen={documentDialogList.indicatorGraphDialog.open}
                close={(): void => closeDocumentDialog('indicatorGraphDialog')}
                indicator={documentDialogList.indicatorGraphDialog.props?.indicator}
                isTopRowIndicator={documentDialogList.indicatorGraphDialog.props?.isTopRowIndicator}
            />
            {/* SHVK */}
            <UploadCustomIndicatorDialog
                isOpen={documentDialogList.uploadCustomIndicatorDialog.open}
                close={(): void => closeDocumentDialog('uploadCustomIndicatorDialog')}
                indicatorPackageTheme={documentDialogList.uploadCustomIndicatorDialog.props?.indicatorPackageTheme}
            />
            <IndicatorDescriptionDialog
                isOpen={documentDialogList.indicatorDescriptionDialog.open}
                close={(): void => closeDocumentDialog('indicatorDescriptionDialog')}
                indicator={documentDialogList.indicatorDescriptionDialog.props?.indicator}
            />
            <IndicatorWithNoteDialog
                isOpen={documentDialogList.indicatorWithNoteDialog.open}
                close={(): void => closeDocumentDialog('indicatorWithNoteDialog')}
                indicator={documentDialogList.indicatorWithNoteDialog.props?.indicator}
                indicatorPackage={documentDialogList.indicatorWithNoteDialog.props?.indicatorPackage}
                editing={documentDialogList.indicatorWithNoteDialog.props?.editing}
                toggleEdit={documentDialogList.indicatorWithNoteDialog.props?.toggleEdit}
            />
            <EditIndicatorListDialog
                isOpen={documentDialogList.editIndicatorListDialog.open}
                close={(): void => closeDocumentDialog('editIndicatorListDialog')}
                indicatorPackageTheme={documentDialogList.editIndicatorListDialog.props?.indicatorPackageTheme}
            />
            <ComparisonSettingsDialog
                isOpen={documentDialogList.comparisonSettingsDialog.open}
                close={(): void => closeDocumentDialog('comparisonSettingsDialog')}
            />
            <IndicatorModelDialog
                isOpen={documentDialogList.indicatorModelDialog.open}
                close={(): void => closeDocumentDialog('indicatorModelDialog')}
            />
            <FocusAreaModelDialog
                isOpen={documentDialogList.focusAreaModelDialog.open}
                close={(): void => {
                    void document.unlockFocusAreaChapter();
                    closeDocumentDialog('focusAreaModelDialog');
                }}
            />
            <CrossOrganizationDialog
                isOpen={documentDialogList.crossOrganizationDialog.open}
                close={(): void => closeDocumentDialog('crossOrganizationDialog')}
                type={documentDialogList.crossOrganizationDialog.props?.type}
            />
            {/* EVA */}
            <EditEvaModelDialog
                isOpen={documentDialogList.editEvaModelDialog.open}
                close={(): void => {
                    void document.unlockDecisionOptionChapter();
                    closeDocumentDialog('editEvaModelDialog');
                }}
            />
            <EvaDecisionOptionCompareDialog
                isOpen={documentDialogList.evaDecisionOptionCompareDialog.open}
                close={(): void => closeDocumentDialog('evaDecisionOptionCompareDialog')}
            />
            <AddDecisionOptionDialog
                decisionOption={documentDialogList.addDecisionOptionDialog.props?.decisionOption}
                chapter={documentDialogList.addDecisionOptionDialog.props?.chapter}
                isOpen={documentDialogList.addDecisionOptionDialog.open}
                close={(): void => closeDocumentDialog('addDecisionOptionDialog')}
            />
            <EvaIndicatorListDialog
                impactOnTarget={documentDialogList.evaIndicatorListDialog.props?.impactOnTarget}
                isOpen={documentDialogList.evaIndicatorListDialog.open}
                close={(): void => closeDocumentDialog('evaIndicatorListDialog')}
            />
            <EditImpactOnTargetNameDialog
                impactOnTarget={documentDialogList.editImpactOnTargetNameDialog.props?.impactOnTarget}
                isOpen={documentDialogList.editImpactOnTargetNameDialog.open}
                close={(): void => closeDocumentDialog('editImpactOnTargetNameDialog')}
            />
            <AddFocusAreaDialog
                chapter={documentDialogList.addFocusAreaDialog.props?.chapter}
                isOpen={documentDialogList.addFocusAreaDialog.open}
                close={(): void => closeDocumentDialog('addFocusAreaDialog')}
            />
            <AddFocusAreaGoalDialog
                focusArea={documentDialogList.addFocusAreaGoalDialog.props?.focusArea}
                isOpen={documentDialogList.addFocusAreaGoalDialog.open}
                close={(): void => closeDocumentDialog('addFocusAreaGoalDialog')}
            />
            <AddFocusAreaActionDialog
                focusArea={documentDialogList.addFocusAreaActionDialog.props?.focusArea}
                goal={documentDialogList.addFocusAreaActionDialog.props?.goal}
                handleEditAction={documentDialogList.addFocusAreaActionDialog.props?.handleEditAction}
                isOpen={documentDialogList.addFocusAreaActionDialog.open}
                close={(): void => closeDocumentDialog('addFocusAreaActionDialog')}
            />
            <AddImpactOnTargetDialog
                perspective={documentDialogList.addImpactOnTargetDialog.props?.perspective}
                isOpen={documentDialogList.addImpactOnTargetDialog.open}
                close={(): void => closeDocumentDialog('addImpactOnTargetDialog')}
            />
            <AddPerspectiveDialog
                isOpen={documentDialogList.addPerspectiveDialog.open}
                close={(): void => closeDocumentDialog('addPerspectiveDialog')}
            />
            <ImpactOnTargetDialog
                isOpen={documentDialogList.impactOnTargetDialog.open}
                close={(): void => closeDocumentDialog('impactOnTargetDialog')}
                perspective={documentDialogList.impactOnTargetDialog.props?.perspective}
                decisionOption={documentDialogList.impactOnTargetDialog.props?.decisionOption}
                impactOnTarget={documentDialogList.impactOnTargetDialog.props?.impactOnTarget}
            />
        </>
    );
}

export default observer(DocumentDialogs);
