import React, { useState, ChangeEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Collapse,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    Add,
    Delete,
    Edit,
    Info,
    Label,
    LabelOff,
    Lock,
    LockOpen,
    Refresh,
    SpeakerNotes,
    SpeakerNotesOff,
    Visibility,
    VisibilityOff,
} from '@mui/icons-material';
import { Chapter, ChapterNumber } from '../../types/document';
import { ButtonEvent } from '../../types/events';
import { Indicator } from '../../types/indicator';
import EvaDecisionOption from './eva/decisionOptionArea/EvaDecisionOption';
import EvaDecisionOptionCompareTable from './eva/comparisonTable/EvaDecisionOptionComparisonTable';
import IndicatorPackageList from './shvk/IndicatorPackageList';
import FocusAreas from './shvk/FocusAreas';
import ShvkTextField from '../../styled/ShvkTextField';
import FroalaChapter from './editor/FroalaChapter';
import IndicatorTop from './shvk/IndicatorTop';
import moment from 'moment';
import useStore from '../../store/storeContext';

interface Props {
    chapter: Chapter;
    showSubAreas: boolean;
    toggleShowSubAreas: () => void;
}

interface State {
    editing: boolean;
    showSummary: boolean;
    showFocusArea: boolean;
    showIndicatorTopTable: boolean;
    showSummaryCircularProgress: boolean;
    showIndicatorTopCircularProgress: boolean;
    createChapterMenu: HTMLElement | null;
    indicatorsForIndicatorTop: Indicator[] | null;
    summaryText: string | null;
}

let typingTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

function EditorChapter(props: Props) {
    const context = useStore();
    const { document, dialog, user, indicator, localization, theming, snackbar } = context;

    const [state, setState] = useState<State>({
        editing: props.chapter.editor === user.userDetails?.userName && props.chapter.locked,
        showFocusArea: props.chapter.focusAreas.length !== 0,
        showSummary: false,
        showIndicatorTopTable: false,
        showSummaryCircularProgress: false,
        showIndicatorTopCircularProgress: false,
        createChapterMenu: null,
        indicatorsForIndicatorTop: null,
        summaryText: null,
    });

    useEffect(() => {
        // This adds scroll-margin-top value to all chapters.
        // It helps side navigation to navigate to the correct spot.
        calculateScrollMarginTop(chapter.orderNumber);
    }, []);

    useEffect(() => {
        if (props.chapter.editor === user.userDetails?.userName && !props.chapter.locked) {
            setState((state) => ({ ...state, editing: false }));
        }
    }, [props.chapter.locked]);

    async function toggleShowIndicatorTopTable(): Promise<void> {
        const { isSummary, isCompareToCountiesMainArea, isCompareToCountiesSubArea, isCompareToWellbeingCountyArea } =
            props.chapter;

        try {
            setState((state) => ({ ...state, showIndicatorTopTable: !state.showIndicatorTopTable }));

            if (
                !state.indicatorsForIndicatorTop &&
                (isCompareToCountiesMainArea || isCompareToCountiesSubArea || isCompareToWellbeingCountyArea)
            ) {
                setState((state) => ({ ...state, showIndicatorTopCircularProgress: true }));
                const indicators = await indicator.fetchDataForIndicatorTop(props.chapter.id);
                setState((state) => ({ ...state, indicatorsForIndicatorTop: indicators }));
            } else if (!indicator.summaryData && isSummary) {
                setState((state) => ({ ...state, showIndicatorTopCircularProgress: true }));
                await indicator.fetchSummaryDataForIndicatorTop();
            }
        } catch (error) {
            snackbar.showError(error.data?.code);
        } finally {
            setState((state) => ({ ...state, showIndicatorTopCircularProgress: false }));
        }
    }

    async function toggleShowSummary(): Promise<void> {
        try {
            if (!state.summaryText && !state.showSummary) {
                setState((state) => ({ ...state, showSummaryCircularProgress: true }));
                const summaryText = await indicator.fetchSummary(props.chapter.id);
                setState((state) => ({ ...state, summaryText }));
            }
            setState((state) => ({ ...state, showSummary: !state.showSummary }));
        } catch (error) {
            snackbar.showError(error.data.key);
        } finally {
            setState((state) => ({ ...state, showSummaryCircularProgress: false }));
        }
    }

    async function toggleEditing(calledFromFroalaChapter = false): Promise<void> {
        try {
            if (state.editing) {
                if (props.chapter.isEvaDecisionOptionsComparisonArea && document.evaDecisionOptionChapter) {
                    await document.unlockChapter(document.evaDecisionOptionChapter.id);
                }
                await document.saveChapter(props.chapter);
                await document.unlockChapter(props.chapter.id);
                if (calledFromFroalaChapter) {
                    snackbar.setMessage(localization.translate('TIME_OUT'));
                    snackbar.setSeverity('info');
                    snackbar.open();
                }
            } else {
                if (props.chapter.isEvaDecisionOptionsComparisonArea && document.evaDecisionOptionChapter) {
                    await document.lockChapter(document.evaDecisionOptionChapter.id);
                }
                await document.lockChapter(props.chapter.id);
            }
            setState((state) => ({ ...state, editing: !state.editing }));
        } catch (error) {
            snackbar.setMessage(error?.data?.message);
            snackbar.setSeverity('error');
            snackbar.open();
        }
    }

    async function removeChapter(): Promise<void> {
        const { translate } = localization;
        const { getConfirmation } = dialog;

        const confirmDialogTitle = translate('CHAPTER_REMOVE');
        const confirmDialogText = props.chapter.isIndicatorArea
            ? translate('CONFIRM_INDICATOR_AREA_REMOVAL_SUBTITLE')
            : props.chapter.isFocusArea
            ? translate('CONFIRM_FOCUS_AREA_REMOVAL_SUBTITLE')
            : translate('CHAPTER_REMOVE_SUBTITLE');

        const confirmation = await getConfirmation(confirmDialogTitle, confirmDialogText, true);

        if (confirmation) {
            await document.removeChapter(props.chapter.id);
            await document.reloadCurrentDocument();
        }
    }

    async function handleChapterSelection(orderNumber: number, chapterLevel: number): Promise<void> {
        await closeChapterMenu();
        await createChapter(orderNumber, chapterLevel);
        await document.reloadCurrentDocument();
    }

    async function createChapter(orderNumber: number, chapterLevel: number): Promise<void> {
        const documentId = document.currentDocument.id;
        await document.createChapter(documentId, orderNumber, chapterLevel);
    }

    function chapterLevelsToString(chapterNumber: ChapterNumber, isNextSameLevelChapter = false): string {
        let string = '';
        let level1 = chapterNumber.level1;
        let level2 = chapterNumber.level2;
        let level3 = chapterNumber.level3;

        if (chapter.chapterLevel === 0 && isNextSameLevelChapter) {
            level1++;
            string += (level1 + document.findSubChapters(chapter.id, true).length).toString();
            return string;
        }

        if (isNextSameLevelChapter) {
            if (level3) {
                level3++;
            } else if (level2) {
                level2++;
            } else {
                level1++;
            }
        }

        if (level1) {
            string += level1.toString();
        }
        if (level2) {
            string += '.' + level2.toString();
        }
        if (level3) {
            string += '.' + level3.toString();
        }

        return string;
    }

    function lastOfSameLevelToString(chapter: Chapter): string {
        let string;
        chapter.chapterLevel === 1
            ? (string =
                  chapter.chapterNumber.level1.toString() +
                  '.' +
                  (document.findSubChapters(chapter.id, true).length + 1).toString())
            : (string =
                  chapter.chapterNumber.level1.toString() +
                  '.' +
                  chapter.chapterNumber.level2 +
                  '.' +
                  (document.findSubChapters(chapter.id, true).length + 1).toString());
        return string;
    }

    function calculateScrollMarginTop(orderNumber: number): void {
        const element = window.document.getElementById('chapter-' + orderNumber);
        if (element) {
            element.style.scrollMarginTop = element.offsetTop < 960 ? '30px' : '-30px';
            return;
        }
    }

    const toggleShowFocusArea = (): void => {
        setState((state) => ({ ...state, showFocusArea: !state.showFocusArea }));
    };

    const toggleFocusArea = (): void => {
        runInAction(() => {
            document.focusAreaTableHidden = !document.focusAreaTableHidden;
        });
    };

    const handleTitleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        runInAction(() => (chapter.title = event.target.value));
        clearTimeout(typingTimeout);

        typingTimeout = setTimeout(async () => {
            chapter.locked && (await context.document.saveChapter(chapter));
        }, 1000);
    };

    const handleCreateChapterClick = (event: ButtonEvent): void => {
        openChapterMenu(event.currentTarget);
    };

    const handleCreateChapterClose = (): void => {
        closeChapterMenu();
    };

    const closeChapterMenu = (): void => {
        setState((state) => ({ ...state, createChapterMenu: null }));
    };

    const openChapterMenu = (target: HTMLElement): void => {
        setState((state) => ({ ...state, createChapterMenu: target }));
    };

    const nextOrderNumberOfSameLevel = (chapterId: number, orderNumber: number): number => {
        const numberOfSubChapters = document.findSubChapters(chapterId).length;
        return orderNumber + numberOfSubChapters + 1;
    };

    const chapterNameOnClick = (): void => {
        const isApproved = document.isCurrentDocumentApproved;
        const { isPreview } = document;
        // TODO check if chapter is locked
        if (!isApproved && !isPreview) {
            void toggleEditing();
        }
    };

    const getChapter = (): void => {
        void document.getChapter(props.chapter.id);
        if (props.chapter.isEvaDecisionOptionsComparisonArea && document.evaDecisionOptionChapter) {
            void document.getChapter(document.evaDecisionOptionChapter.id);
        }
    };

    const { chapter } = props;
    const { translate } = localization;
    const { currentDocument } = document;
    const { isPreview } = document;
    const { sharedData } = document.currentDocument;
    const { spacing, shvkBlue10, palette } = theming;

    // Approved documents are readonly
    const isApproved = document.isCurrentDocumentApproved;
    const isPartOfRegional = currentDocument.organization.isPartOfRegional;

    // Test variables
    const saveInProgress = false;
    const isChapterLockedForCurrentUser = state.editing;
    const isChapterLockedForAnotherUser = false;
    const hasSubChapters = document.hasSubChapters(chapter.id);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        {!state.editing ? (
                            <Box display="flex" style={{ alignItems: 'center' }}>
                                <Typography
                                    variant="h5"
                                    style={{ cursor: isApproved ? 'auto' : 'pointer' }}
                                    onClick={chapterNameOnClick}
                                >
                                    {chapter.chapterNumberString} {chapter.title}
                                    {!isApproved && !isPreview && chapter.tip && (
                                        <Tooltip
                                            placement={'right-start'}
                                            style={{
                                                marginLeft: spacing(1),
                                            }}
                                            title={<span style={{ whiteSpace: 'pre-line' }}>{chapter.tip}</span>}
                                        >
                                            <Info
                                                style={{
                                                    verticalAlign: 'middle',
                                                    paddingBottom: spacing(0.5),
                                                    marginLeft: spacing(1),
                                                }}
                                                color="primary"
                                            />
                                        </Tooltip>
                                    )}
                                </Typography>
                            </Box>
                        ) : (
                            <ShvkTextField
                                label={translate('TITLE')}
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={chapter.title}
                                onChange={handleTitleChange}
                                style={{ marginBottom: spacing(1) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {chapter?.chapterNumberString || ''}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </Box>
                    {!isApproved && !isPreview && (
                        <ButtonGroup
                            style={{ alignItems: 'flex-start', marginLeft: spacing(1) }}
                            id="button-group"
                            color="primary"
                            size="small"
                        >
                            {saveInProgress && (
                                <Button disabled>
                                    <CircularProgress />
                                </Button>
                            )}
                            {isChapterLockedForAnotherUser && !saveInProgress && (
                                <Button disabled style={{ color: palette.error.main }}>
                                    <Lock />
                                    <small> {chapter.editor}</small>
                                </Button>
                            )}
                            {!isChapterLockedForCurrentUser && chapter.isVisible && !saveInProgress && (
                                <Button onClick={() => toggleEditing()}>
                                    <Tooltip title={translate('EDIT_CHAPTER_TEXT_TOOLTIP')}>
                                        <Edit />
                                    </Tooltip>
                                </Button>
                            )}
                            {isChapterLockedForCurrentUser && (
                                <Button style={{ color: palette.error.main }} onClick={() => toggleEditing()}>
                                    <LockOpen />
                                </Button>
                            )}
                            {!saveInProgress && (
                                <Button onClick={getChapter}>
                                    <Tooltip title={translate('REFRESH_CHAPTER_TOOLTIP')}>
                                        <Refresh />
                                    </Tooltip>
                                </Button>
                            )}

                            {chapter.isFocusArea && (chapter.focusAreas.length === 0 || !state.showFocusArea) && (
                                <Tooltip
                                    title={
                                        state.showFocusArea
                                            ? translate('HIDE_FOCUS_AREA_TABLE')
                                            : translate('SHOW_FOCUS_AREA_TABLE')
                                    }
                                >
                                    <Button onClick={toggleShowFocusArea}>
                                        {state.showFocusArea ? <Visibility /> : <VisibilityOff />}
                                    </Button>
                                </Tooltip>
                            )}

                            {chapter.isCompareToCountiesSubArea && (
                                <Tooltip
                                    title={state.showSummary ? translate('HIDE_SUMMARY') : translate('SHOW_SUMMARY')}
                                >
                                    <Button onClick={toggleShowSummary}>
                                        {state.showSummary ? <SpeakerNotes /> : <SpeakerNotesOff />}
                                    </Button>
                                </Tooltip>
                            )}

                            {(chapter.isSummary ||
                                chapter.isCompareToCountiesMainArea ||
                                chapter.isCompareToCountiesSubArea ||
                                (chapter.isCompareToWellbeingCountyArea && isPartOfRegional)) && (
                                <Tooltip
                                    title={
                                        state.showIndicatorTopTable
                                            ? translate('HIDE_INDICATOR_TOPS')
                                            : translate('SHOW_INDICATOR_TOPS')
                                    }
                                >
                                    <Button onClick={toggleShowIndicatorTopTable}>
                                        {state.showIndicatorTopTable ? <Label /> : <LabelOff />}
                                    </Button>
                                </Tooltip>
                            )}

                            {chapter.isCompareToCountiesMainArea && (
                                <Tooltip
                                    title={props.showSubAreas ? translate('HIDE_COUNTIES') : translate('SHOW_COUNTIES')}
                                >
                                    <Button onClick={props.toggleShowSubAreas}>
                                        {props.showSubAreas ? <Visibility /> : <VisibilityOff />}
                                    </Button>
                                </Tooltip>
                            )}

                            {!isChapterLockedForAnotherUser && !saveInProgress && chapter.isRemovable && (
                                <Button
                                    onClick={removeChapter}
                                    disabled={hasSubChapters}
                                    sx={{
                                        '&.Mui-disabled': {
                                            borderColor: '#86a0b1',
                                        },
                                    }}
                                    style={{ pointerEvents: hasSubChapters ? 'all' : undefined }}
                                >
                                    <Tooltip
                                        title={
                                            hasSubChapters
                                                ? translate('REMOVE_SUBCHAPTERS_FIRST')
                                                : translate('REMOVE_CHAPTER_NUMBER').replace('{{value}}', '')
                                        }
                                    >
                                        <Delete />
                                    </Tooltip>
                                </Button>
                            )}

                            {!chapter.isCompareToCountiesMainArea &&
                                !chapter.isCompareToCountiesSubArea &&
                                !chapter.isIntroduction &&
                                !chapter.isSummary && (
                                    <Button onClick={handleCreateChapterClick}>
                                        <Add />
                                    </Button>
                                )}
                        </ButtonGroup>
                    )}
                    <Menu
                        id="create-chapter-menu"
                        variant="selectedMenu"
                        PaperProps={{
                            style: {
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                            },
                        }}
                        anchorEl={state.createChapterMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        open={Boolean(state.createChapterMenu)}
                        onClose={handleCreateChapterClose}
                    >
                        {chapter.chapterLevel !== 0 && (
                            <MenuItem
                                onClick={() => {
                                    void handleChapterSelection(chapter.orderNumber, chapter.chapterLevel);
                                }}
                            >
                                {chapterLevelsToString(chapter.chapterNumber)}
                            </MenuItem>
                        )}
                        {document.findSubChapters(chapter.id, true).map((mappedChapter: Chapter) => (
                            <MenuItem
                                key={mappedChapter.id}
                                onClick={() => {
                                    void handleChapterSelection(mappedChapter.orderNumber, mappedChapter.chapterLevel);
                                }}
                            >
                                {chapterLevelsToString(mappedChapter.chapterNumber)}
                            </MenuItem>
                        ))}
                        {chapter.chapterLevel !== 0 &&
                        chapter.chapterLevel !== 3 &&
                        !chapter.isCompareToCountiesSubArea ? (
                            <MenuItem
                                onClick={() => {
                                    void handleChapterSelection(
                                        nextOrderNumberOfSameLevel(chapter.id, chapter.orderNumber),
                                        chapter.chapterLevel + 1,
                                    );
                                }}
                            >
                                {lastOfSameLevelToString(chapter)}
                            </MenuItem>
                        ) : null}
                        <MenuItem
                            onClick={() => {
                                const chapterLevel = chapter.chapterLevel !== 0 ? chapter.chapterLevel : 1;
                                void handleChapterSelection(
                                    nextOrderNumberOfSameLevel(chapter.id, chapter.orderNumber),
                                    chapterLevel,
                                );
                            }}
                        >
                            {chapterLevelsToString(chapter.chapterNumber, true)}
                        </MenuItem>
                    </Menu>
                </Box>
            </Grid>
            {!isApproved && !isPreview && (
                <Grid item xs={12}>
                    <Box mb={2}>
                        <Typography variant="caption">
                            <i>
                                {translate('CHAPTER_LAST_UPDATED')} {chapter.editor}{' '}
                                {moment(chapter.lastUpdated).format('DD.MM.YYYY HH:mm')}
                            </i>
                        </Typography>
                    </Box>
                </Grid>
            )}
            {chapter.isVisible && (
                <Grid item xs={12}>
                    {chapter.isEvaDecisionOptionsComparisonArea && (
                        <>
                            <EvaDecisionOptionCompareTable chapter={chapter} editing={state.editing} />
                            {isApproved && document.isCurrentDocumentEvaMini && (
                                <Typography>{currentDocument.acceptanceClause}</Typography>
                            )}
                        </>
                    )}

                    {chapter.isEvaDecisionOptionArea ? (
                        <EvaDecisionOption chapter={chapter} editing={state.editing} />
                    ) : state.editing && !chapter.isEvaDecisionOptionsComparisonArea ? (
                        <FroalaChapter chapter={chapter} closeTextFieldAfterTimeout={() => toggleEditing(true)} />
                    ) : (
                        <Typography
                            className="fr-view"
                            component="div"
                            dangerouslySetInnerHTML={{ __html: chapter.text }}
                        />
                    )}

                    {chapter.isApprovedArea && isApproved && (
                        <Typography>{currentDocument.acceptanceClause}</Typography>
                    )}

                    {/* TODO */}
                    {/*{chapter.isIndicatorArea && <IndicatorDetails />}*/}

                    {chapter.indicatorPackages?.length > 0 && (
                        <IndicatorPackageList
                            chapter={chapter}
                            isApproved={isApproved}
                            isPreview={isPreview}
                            sharedData={sharedData}
                        />
                    )}

                    {chapter.isFocusArea && state.showFocusArea && (
                        <>
                            <Button variant="outlined" onClick={toggleFocusArea} style={{ marginTop: spacing(1) }}>
                                {document.focusAreaTableHidden
                                    ? translate('SHOW_FOCUS_AREA_TABLE')
                                    : translate('HIDE_FOCUS_AREA_TABLE')}
                            </Button>
                            <Collapse in={!document.focusAreaTableHidden}>
                                <FocusAreas chapter={chapter} editing={state.editing} />
                            </Collapse>
                        </>
                    )}

                    {state.showSummary && (
                        <Box mt={4} p={2} pl={2} style={{ backgroundColor: shvkBlue10 }}>
                            {state.showSummaryCircularProgress ? (
                                <Box textAlign="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    {state.summaryText ? (
                                        <Typography
                                            className="fr-view"
                                            component="div"
                                            dangerouslySetInnerHTML={{ __html: state.summaryText }}
                                        />
                                    ) : (
                                        <Typography>
                                            <i>{translate('SUMMARY_NOT_FOUND')}</i>
                                        </Typography>
                                    )}
                                </>
                            )}
                        </Box>
                    )}

                    {state.showIndicatorTopTable && (
                        <Box mt={4} p={2} pl={2} style={{ backgroundColor: shvkBlue10 }}>
                            {state.showIndicatorTopCircularProgress ? (
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <>
                                    {chapter.isSummary && indicator.summaryData && (
                                        <IndicatorTop chapter={chapter} indicators={indicator.summaryData} />
                                    )}
                                    {!chapter.isSummary && state.indicatorsForIndicatorTop && (
                                        <IndicatorTop chapter={chapter} indicators={state.indicatorsForIndicatorTop} />
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

export default observer(EditorChapter);
