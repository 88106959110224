import React from 'react';
import { observer } from 'mobx-react-lite';
import { Chapter } from '../../../types/document';
import { Grid } from '@mui/material';
import IndicatorPackageListItem from './IndicatorPackageListItem';

interface Props {
    chapter: Chapter;
    isApproved: boolean;
    isPreview: boolean;
    sharedData: string;
}

function IndicatorPackageList(props: Props) {
    return (
        <Grid container spacing={2}>
            {props.chapter.indicatorPackages.map((indicatorPackage) => (
                <Grid item xs={12} key={indicatorPackage.id}>
                    <IndicatorPackageListItem
                        indicatorPackage={indicatorPackage}
                        isApproved={props.isApproved}
                        isPreview={props.isPreview}
                        sharedData={props.sharedData}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default observer(IndicatorPackageList);
