import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { ArrowDropDown, ArrowLeft, Edit, LockOpen, Refresh } from '@mui/icons-material';
import { IndicatorPackage } from '../../../types/indicator';
import IndicatorPackageThemeItem from './IndicatorPackageThemeItem';
import FroalaIndicatorNote from '../editor/FroalaIndicatorNote';
import useStore from '../../../store/storeContext';

interface Props {
    indicatorPackage: IndicatorPackage;
    isApproved: boolean;
    isPreview: boolean;
    sharedData: string;
}

interface State {
    expanded: boolean;
    editing: boolean;
}

function IndicatorPackageListItem(props: Props) {
    const { document, indicator, localization, loadingIndicator, snackbar, theming } = useStore();
    const { isPreview, isApproved, sharedData } = props;

    const [state, setState] = useState<State>({
        expanded: false,
        editing: false,
    });

    useEffect(() => {
        setState((state) => ({ ...state, expanded: false, editing: false }));
        return () => {
            if (props.indicatorPackage.locked) {
                // Unlock the locked package if the component gets unmounted.
                void document.unlockPackage(props.indicatorPackage.id);
                setState((state) => ({ ...state, editing: false }));
            }
        };
    }, []);

    useEffect(() => {
        if (!state.expanded && state.editing) {
            void document.unlockPackage(props.indicatorPackage.id);
            setState((state) => ({ ...state, editing: false }));
        }
    }, [state.expanded, state.editing]);

    async function toggleEditing(isEditingActive: boolean): Promise<void> {
        try {
            isEditingActive
                ? await document.unlockPackage(props.indicatorPackage.id)
                : await document.lockPackage(props.indicatorPackage.id);
            setState((state) => ({ ...state, editing: !isEditingActive }));
        } catch (error) {
            snackbar.showError(error?.data?.code);
        }
    }

    async function togglePackage(): Promise<void> {
        if (props.indicatorPackage.themes.length > 0) {
            // We already got the themes. No need to fetch again.
            setState((state) => ({ ...state, expanded: !state.expanded }));
            return;
        }
        loadingIndicator.show();
        try {
            await indicator.fetchPackageThemes(props.indicatorPackage.id);
            setState((state) => ({ ...state, expanded: !state.expanded }));
        } catch (error) {
            snackbar.showFetchFailedMessage(error.data?.code);
        } finally {
            loadingIndicator.hide();
        }
    }

    const { indicatorPackage } = props;
    const { translate } = localization;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6">{indicatorPackage.name}</Typography>
                    </Box>
                    <ButtonGroup color="primary" size="small">
                        <Button onClick={togglePackage}>{state.expanded ? <ArrowDropDown /> : <ArrowLeft />}</Button>
                    </ButtonGroup>
                </Box>
            </Grid>
            {state.expanded && (
                <Grid item xs={12}>
                    {indicatorPackage.themes.map((theme) => (
                        <IndicatorPackageThemeItem
                            theme={theme}
                            key={theme.id}
                            editing={state.editing}
                            toggleEdit={toggleEditing}
                            sharedData={sharedData}
                            isPreview={isPreview}
                            isApproved={isApproved}
                        />
                    ))}
                    {
                        // In preview mode, show summary area only if shared data is either SHARE_SUMMARY or SHARE_INDICATORDESCRIPTION
                        (sharedData == 'DOCUMENT_APPROVAL_SHARE_SUMMARY' ||
                            sharedData == 'DOCUMENT_APPROVAL_SHARE_INDICATORDESCRIPTION' ||
                            !isPreview) && (
                            <Box style={{ padding: theming.spacing(2) }}>
                                <Box display="flex">
                                    <Box flexGrow={1}>
                                        <Typography variant="h6">
                                            {translate('SUMMARY')} - {indicatorPackage?.name}
                                        </Typography>
                                    </Box>
                                    <ButtonGroup id="button-group" color="primary" size="small">
                                        {!isApproved && (
                                            <Button
                                                onClick={() => toggleEditing(state.editing)}
                                                style={{
                                                    color: !state.editing
                                                        ? theming.palette.primary.main
                                                        : theming.palette.error.main,
                                                }}
                                            >
                                                {!state.editing ? <Edit /> : <LockOpen />}
                                            </Button>
                                        )}
                                        <Button onClick={() => document.getIndicatorPackage(indicatorPackage.id)}>
                                            <Refresh />
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                                <Typography variant="caption">
                                    <i>
                                        {translate('CHAPTER_LAST_UPDATED')} {indicatorPackage?.editor}{' '}
                                        {moment(indicatorPackage?.lastUpdated).format('DD.MM.YYYY HH:mm')}
                                    </i>
                                </Typography>
                                {state.editing && indicatorPackage ? (
                                    <FroalaIndicatorNote indicatorPackage={indicatorPackage} />
                                ) : (
                                    <Typography
                                        className="fr-view"
                                        dangerouslySetInnerHTML={{ __html: props.indicatorPackage?.notes || '' }}
                                    />
                                )}
                            </Box>
                        )
                    }
                </Grid>
            )}
        </Grid>
    );
}

export default observer(IndicatorPackageListItem);
