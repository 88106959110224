import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { Add, CreateNewFolder, Delete, Save } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
    AddIndicatorDialogIndicator,
    AddIndicatorParams,
    Indicator,
    IndicatorPackage,
    IndicatorPackageTheme,
} from '../../../types/indicator';
import { IndicatorNestableItem, IndicatorRenderItemArgs } from '../../../types/nestable';
import { Chapter } from '../../../types/document';
import DangerIconButton from '../../../styled/DangerIconButton';
import AddIndicatorDialog from '../dialogs/AddIndicatorDialog';
import useStore, { rootModule } from '../../../store/storeContext';

const PREFIX = 'IndicatorNestableComponent';

const classes = {
    paper: `${PREFIX}-paper`,
    depth1: `${PREFIX}-depth1`,
    depth2: `${PREFIX}-depth2`,
    depth3: `${PREFIX}-depth3`,
    indicator: `${PREFIX}-indicator`,
    type: `${PREFIX}-type`,
    unsaved: `${PREFIX}-unsaved`,
};

const { theming } = rootModule;

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`& .${classes.paper}`]: {
        position: 'relative',
        padding: theme.spacing(0.5),
        cursor: 'pointer',
    },

    [`& .${classes.depth1}`]: {
        backgroundColor: theming.shvkBlueGray60,
    },

    [`& .${classes.depth2}`]: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        backgroundColor: theming.shvkBlueGray40,
    },

    [`& .${classes.depth3}`]: {
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        backgroundColor: theming.shvkBlueGray20,
    },

    [`& .${classes.indicator}`]: {
        backgroundColor: theming.shvkSavu10,
        paddingTop: 0,
        paddingBottom: 0,
    },

    [`& .${classes.type}`]: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(1),
    },

    [`& .${classes.unsaved}`]: {
        backgroundColor: theming.shvkBlueGray10,
        color: theming.shvkDarkGrey,
    },
}));

interface Props {
    args: IndicatorRenderItemArgs;
    handleTextChange(value: string, id: number): void;
    addItem(parentId: number | null): void;
    removeItem(item: IndicatorNestableItem): void;
    handleAddIndicator(indicator: Indicator, parentId: number): void;
    save(): void;
}

interface State {
    editing: boolean;
    open: boolean;
}

function IndicatorNestableComponent(props: Props) {
    const { document, indicator, dialog, localization } = useStore();

    const [state, setState] = useState<State>({
        editing: false,
        open: false,
    });

    function getIndicatorPackageTheme(): IndicatorPackageTheme {
        const chapter = document.currentDocument.chapters.find((chapter: Chapter) => chapter.isIndicatorArea);

        let theme: IndicatorPackageTheme = {} as IndicatorPackageTheme;

        chapter?.indicatorPackages.forEach((indicatorPackage: IndicatorPackage) =>
            indicatorPackage.themes.forEach((indicatorPackageTheme: IndicatorPackageTheme) => {
                if (indicatorPackageTheme.id === props.args.item.id) {
                    theme = indicatorPackageTheme;
                }
            }),
        );
        return theme;
    }

    const handleDialog = () => {
        setState((state) => ({ ...state, open: !state.open }));
    };

    const toggleEdit = () => {
        if (!props.args.item.isIndicator) {
            setState((state) => ({ ...state, editing: !state.editing }));
        }
    };

    async function remove(): Promise<void> {
        const confirmation = await dialog.getConfirmation();
        if (!confirmation) return;

        props.removeItem(props.args.item);
    }

    async function saveIndicators(indicators: AddIndicatorDialogIndicator[]): Promise<void> {
        const params: AddIndicatorParams = {
            documentId: document.currentDocument.id,
            indicators: indicators.map((indicator) => {
                return {
                    indicatorId: indicator.id,
                    source: indicator.source,
                    direction: indicator.direction,
                };
            }),
            theme: indicatorPackageTheme,
            themeId: indicatorPackageTheme.id,
        };
        const newIndicators = await indicator.addIndicator(params);
        newIndicators.forEach((newIndicator) => props.handleAddIndicator(newIndicator, indicatorPackageTheme.id));
    }

    const indicatorPackageTheme = getIndicatorPackageTheme();
    const { translate } = localization;
    const { args, addItem, handleTextChange } = props;
    const { depth, isIndicator, id } = props.args.item;

    return (
        <StyledPaper
            variant="outlined"
            className={`${classes.paper} ${
                isIndicator
                    ? classes.indicator
                    : depth === 1
                    ? classes.depth1
                    : depth === 2
                    ? classes.depth2
                    : classes.depth3
            } ${id < 0 && classes.unsaved}`}
        >
            <Box display="flex" alignItems="center">
                {args.collapseIcon}
                {depth === 4 && args.item.indicatorType && (
                    <Typography className={classes.type}>({args.item.indicatorType})</Typography>
                )}
                {state.editing ? (
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(event): void => handleTextChange(event.target.value, args.item.id)}
                        value={args.item.text}
                        onBlur={toggleEdit}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') toggleEdit();
                        }}
                    />
                ) : (
                    <Typography onClick={toggleEdit}>{args.item.text || ' - '}</Typography>
                )}
                <Box flexGrow={1} />
                {id < 0 && (
                    <IconButton size="small" color="primary" onClick={props.save}>
                        <Save />
                    </IconButton>
                )}
                {(depth === 1 || depth === 2) && (
                    <IconButton size="small" color="primary" onClick={() => addItem(args.item.id)}>
                        <CreateNewFolder />
                    </IconButton>
                )}
                {depth === 3 && id > 0 && (
                    <Tooltip title={translate('ADD_INDICATOR')}>
                        <IconButton size="small" color="primary" onClick={handleDialog}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                )}
                <DangerIconButton
                    size="small"
                    onClick={remove}
                    disabled={args.item.children.length > 0 || !args.item.isRemovable}
                >
                    <Delete />
                </DangerIconButton>
            </Box>
            <AddIndicatorDialog
                isOpen={state.open}
                close={handleDialog}
                indicatorPackageTheme={indicatorPackageTheme}
                saveIndicators={saveIndicators}
            />
        </StyledPaper>
    );
}

export default observer(IndicatorNestableComponent);
