import React, { useState, useEffect, ReactNode } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Hidden,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
    Tooltip as MuiTooltip,
} from '@mui/material';
import { Close, Comment, GetApp, Save } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {
    FetchIndicatorDataParams,
    GraphDataItem,
    Indicator,
    IndicatorData,
    IndicatorGender,
    SaveIndicatorParams,
} from '../../../types/indicator';
import { TextFieldEvent } from '../../../types/events';
import IndicatorComparison from '../shvk/IndicatorComparison';
import ShvkTextField from '../../../styled/ShvkTextField';
import ShvkButton from '../../../styled/ShvkButton';
import FroalaIndicatorDescription from '../editor/FroalaIndicatorDescription';
import download from 'downloadjs';
import useStore from '../../../store/storeContext';

const PREFIX = 'IndicatorGraphDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1280,
        },
    },
    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    isOpen: boolean;
    close(): void;
    indicator?: Indicator | null;
    isTopRowIndicator?: boolean;
}

interface State {
    activeTab: TabName;
    minLimit: string;
    maxLimit: string;
    targetLimit: string;
    yAxisScale: number;
    graphRef: any;
    editorOpen: boolean;
}

interface StoreState {
    femaleData: IndicatorData[];
    maleData: IndicatorData[];
    totalData: IndicatorData[];
    indicator: Indicator | null;
    gender: string;
    graphData: GraphDataItem[];
    isGenderDataAvailable: boolean;
    organizationsForGraph: string[];
    genderData: IndicatorData[] | undefined;
    tableYears: number[];
    handleGenderChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type TabName = 'ORGANIZATION_TABLE' | 'DESCRIPTION';
type FormField = 'maxLimit' | 'minLimit' | 'targetLimit';

function IndicatorGraphDialog(props: Props) {
    const context = useStore();

    const [state, setState] = useState<State>({
        activeTab: 'ORGANIZATION_TABLE',
        minLimit: '',
        maxLimit: '',
        targetLimit: '',
        yAxisScale: 0,
        graphRef: null,
        editorOpen: false,
    });

    const localStore = useLocalObservable<StoreState>(() => ({
        femaleData: [],
        maleData: [],
        totalData: [],
        gender: 'total',
        indicator: null,

        get graphData(): GraphDataItem[] {
            const {
                removeDuplicatesFromIndicatorData,
                groupIndicatorDataByOrganization,
                filterIndicatorDataBetweenCompareYears,
            } = context.indicator;
            const { currentDocument } = context.document;

            if (!this.indicator) return [];
            let data: IndicatorData[] = [];
            if (this.gender === 'total') data = this.totalData;
            else if (this.gender === 'female') data = this.femaleData;
            else if (this.gender === 'male') data = this.maleData;

            const currentOrganizationName = currentDocument.organization.name;
            const uniques = removeDuplicatesFromIndicatorData(data);
            const dataBetweenCompareYears = filterIndicatorDataBetweenCompareYears(uniques);
            const sortedByYear = dataBetweenCompareYears.sort((a, b) => a.year - b.year);
            const organizationData = groupIndicatorDataByOrganization(sortedByYear);

            let colorIndex = 0;
            let graphData: GraphDataItem[] = organizationData.map((dataItem) => {
                const isCurrentOrganization = dataItem.organizationName === currentOrganizationName;
                let color = context.theming.graphOrganizationHomeColor;

                if (!isCurrentOrganization) {
                    color =
                        colorIndex < context.theming.graphOrganizationColors.length
                            ? context.theming.graphOrganizationColors[colorIndex++]
                            : context.theming.graphOrganizationColors[0];
                }

                return {
                    color,
                    name: dataItem.organizationName,
                    shortName: dataItem.organizationShortName,
                    orderNumber: getOrganizationOrderNumber(dataItem.organizationName),
                    strokeWidth: isCurrentOrganization ? 3 : 1.5,
                    values: dataItem.data,
                };
            });

            // If indicator is from theme, patch it according to settings.
            if (this.indicator.source.code === 'CHP_SOTKANET') {
                graphData = patchDataWithOrganizationsFromComparisonSettings(graphData);
            }

            //Temporary fix: For some reason map function adds extra objects to graphData. This filter function removes extra objects.
            return graphData.filter((d) => d.color !== '').sort((a, b) => a.orderNumber - b.orderNumber);
        },
        get isGenderDataAvailable(): boolean {
            return this.femaleData.length > 0 || this.maleData.length > 0;
        },

        get tableYears(): number[] {
            if (context.document.isEvaDocument(context.document.currentDocument)) {
                return this.totalData.map((data) => data.year);
            }

            return context.indicator
                .dataForLargeGraph(this.graphData)
                .flatMap((item) => item.year)
                .sort((a: number, b: number) => a - b);
        },

        get organizationsForGraph(): string[] {
            return localStore.graphData.map((data) => data.shortName || data.name);
        },

        get genderData(): IndicatorData[] | undefined {
            if (this.gender === 'female') return this.femaleData;
            else if (this.gender === 'male') return this.maleData;
        },

        handleGenderChange(event: React.ChangeEvent<HTMLInputElement>): void {
            this.gender = event.target.value as IndicatorGender;
        },
    }));

    useEffect(() => {
        props.isOpen && void init();
        return () => {
            setState((state) => ({ ...state, activeTab: 'ORGANIZATION_TABLE' }));
            runInAction(() => (localStore.gender = 'total'));
        };
    }, [props.isOpen]);

    async function init(): Promise<void> {
        context.loadingIndicator.show();
        try {
            const minLimit =
                props.indicator?.alarm_min?.toString() !== '-1' ? props.indicator?.alarm_min?.toString() : '';
            const maxLimit =
                props.indicator?.alarm_max?.toString() !== '-1' ? props.indicator?.alarm_max?.toString() : '';
            const targetLimit =
                props.indicator?.target_limit?.toString() !== '-1' ? props.indicator?.target_limit?.toString() : '';

            const yAxisScale = context.document.currentDocument.documentIndicatorSetting?.graphScale || 0;

            setState((state) => ({
                ...state,
                activeTab: 'ORGANIZATION_TABLE',
                graphRef: null,
                editorOpen: false,
                minLimit: minLimit || '',
                maxLimit: maxLimit || '',
                targetLimit: targetLimit || '',
                yAxisScale,
            }));

            // Filter data from props.indicator
            let femaleData = props.indicator?.data.filter((data) => data.gender === 'female') || [];
            let maleData = props.indicator?.data.filter((data) => data.gender === 'male') || [];
            let totalData = props.indicator?.data.filter((data) => data.gender === 'total') || [];

            if (
                (props.indicator?.source.code === 'CHP_SOTKANET' || props.indicator?.source.code === 'SOTKANET') &&
                !context.document.isCurrentDocumentEva
            ) {
                femaleData = femaleData.length > 0 ? femaleData : await getFilteredData('female');
                maleData = maleData.length > 0 ? maleData : await getFilteredData('male');
                totalData = totalData.length > 0 ? totalData : await getFilteredData('total');
            } else {
                totalData = totalData.length > 0 ? totalData : await getFilteredData('total');
            }

            runInAction(() => {
                localStore.femaleData = femaleData;
                localStore.maleData = maleData;
                localStore.totalData = totalData;
                localStore.indicator = props.indicator ?? null;
            });
        } finally {
            context.loadingIndicator.hide();
        }
    }

    async function getFilteredData(gender: IndicatorGender): Promise<IndicatorData[]> {
        const { currentDocument } = context.document;
        const settings = context.indicator.comparisonSettings;

        const organizationId = context.organization.currentOrganizationId;

        if (!organizationId || !props.indicator) {
            context.snackbar.showError();
            return [];
        }

        try {
            const params: FetchIndicatorDataParams = {
                documentId: currentDocument.id,
                genderFilter: gender,
                indicatorId: props.indicator.id,
                organizationIds: settings ? settings.organizations.map((org) => org.id) : [organizationId],
                source: props.indicator.source,
            };

            return await context.indicator.fetchIndicatorData(params);
        } catch (error) {
            context.snackbar.showFetchFailedMessage(error.data?.code);
            return [];
        }
    }

    async function saveIndicatorGraph(ref: any): Promise<void> {
        if (ref.container) {
            const svg = ref.container.children[0];
            const svgString = new XMLSerializer().serializeToString(svg);
            const svgStringWithBackground = svgString.replace(
                '><',
                '><rect width="100%" height="100%" fill="white"/><',
            );

            try {
                context.loadingIndicator.show();
                const image = await context.document.createGraphImage(svgStringWithBackground);
                download(`data:image/png;base64,${image}`, `${props.indicator?.name}.png`, '.png');
            } catch (error) {
                context.snackbar.showError(error.data?.code);
            } finally {
                context.loadingIndicator.hide();
            }
        }
    }

    async function saveLimits(): Promise<void> {
        const { indicator } = props;
        if (!indicator) return;

        const max = Number(state.maxLimit) || -1;
        const min = Number(state.minLimit) || -1;
        const target = Number(state.targetLimit) || -1;

        try {
            const params: SaveIndicatorParams = {
                alarm_max: max,
                alarm_min: min,
                target: target,
                source: indicator.source,
                indicatorId: indicator.id,
            };
            await context.indicator.saveIndicator(params);

            runInAction(() => {
                indicator.alarm_max = max;
                indicator.alarm_min = min;
                indicator.target_limit = target;
            });

            context.snackbar.showSuccess();
        } catch (e) {
            context.snackbar.showError(e.data?.code);
        }
    }

    function patchDataWithOrganizationsFromComparisonSettings(data: GraphDataItem[]): GraphDataItem[] {
        const { comparisonSettings } = context.indicator;
        if (!comparisonSettings) return data;

        // Some organizations may not have any data. Add empty data objects.
        comparisonSettings.organizations.forEach((organization) => {
            const organizationIsMissingFromList = !data.some((item) => item.name === organization.name);
            if (organizationIsMissingFromList) {
                data.push({
                    color: '',
                    name: organization.name,
                    shortName: organization.shortName ? organization.shortName : undefined,
                    orderNumber: getOrganizationOrderNumber(organization.name),
                    strokeWidth: 0,
                    values: [],
                });
            }
        });

        return data;
    }

    function getOrganizationOrderNumber(organizationName: string): number {
        const { comparisonSettings } = context.indicator;
        if (!comparisonSettings) return 0;
        const organization = comparisonSettings.organizations.find((org) => org.name === organizationName);
        return organization?.orderNumber || 0;
    }

    function labelContent(props: any): ReactNode {
        let letterCounter = 0;
        let lineCounter = 0;

        return (
            <>
                {localStore.organizationsForGraph.map((org, i) => {
                    if (letterCounter + org.length > 85) {
                        letterCounter = 0;
                        lineCounter += 1;
                    }
                    if (org.length > 20) {
                        letterCounter += 23;
                    } else {
                        letterCounter += org.length + 3;
                    }
                    return (
                        <g key={org}>
                            <circle
                                cx={props.viewBox.x + 760 - letterCounter * 8}
                                cy={props.viewBox.y - 11 - lineCounter * 18}
                                r={6}
                                fill={context.theming.graphOrganizationColors[i]}
                            />
                            <text
                                fontFamily="Work sans"
                                fontSize="14"
                                color="black"
                                x={props.viewBox.x + 760 + 8 - letterCounter * 8}
                                y={props.viewBox.y - 6 - lineCounter * 18}
                            >
                                {org.length >= 20 ? org.slice(0, 20) + '...' : org}
                            </text>
                        </g>
                    );
                })}
            </>
        );
    }

    const handleChange = (event: TextFieldEvent, field: FormField): void => {
        setState((state) => ({ ...state, [field]: event.target.value }));
    };

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newIndex: TabName): void => {
        setState((state) => ({ ...state, activeTab: newIndex }));
    };

    const { translate } = context.localization;
    const { dynamicMarginTopForGraphLabel, dataForLargeGraph } = context.indicator;
    const { isCurrentDocumentApproved } = context.document;

    return (
        <StyledDialog
            open={props.isOpen}
            onClose={props.close}
            aria-labelledby="indicator-graph-dialog-title"
            maxWidth={'lg'}
        >
            <DialogTitle id="indicator-graph-dialog-title">
                {translate('INDICATOR')}: {props.indicator?.name}
            </DialogTitle>
            <DialogContent dividers>
                <Paper variant="outlined" className={classes.paper}>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <ResponsiveContainer width="99%" height="99%" minHeight="450px">
                                <LineChart
                                    ref={(ref) => (state.graphRef = ref)}
                                    data={dataForLargeGraph(localStore.graphData)}
                                    margin={{
                                        top: dynamicMarginTopForGraphLabel(localStore.organizationsForGraph),
                                        right: 27,
                                        left: 0,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid />
                                    <XAxis
                                        fontFamily="Work sans"
                                        fontSize="14"
                                        color="black"
                                        stroke="black"
                                        dataKey="year"
                                        ticks={localStore.tableYears}
                                        type="number"
                                        domain={[
                                            localStore.tableYears[0],
                                            localStore.tableYears[localStore.tableYears.length - 1],
                                        ]}
                                    />
                                    <YAxis
                                        fontFamily="Work sans"
                                        fontSize="14"
                                        color="black"
                                        stroke="black"
                                        type="number"
                                        domain={[
                                            (dataMin: number): number =>
                                                Math.floor(
                                                    Math.min(
                                                        dataMin,
                                                        state.minLimit ? Number(state.minLimit) : dataMin,
                                                        state.targetLimit ? Number(state.targetLimit) : dataMin,
                                                        state.yAxisScale ? dataMin : 0,
                                                    ),
                                                ),
                                            (dataMax: number): number =>
                                                Math.ceil(
                                                    Math.max(
                                                        dataMax,
                                                        state.maxLimit ? Number(state.maxLimit) : dataMax,
                                                        state.targetLimit ? Number(state.targetLimit) : dataMax,
                                                    ),
                                                ),
                                        ]}
                                    >
                                        <Label
                                            content={(props) => labelContent(props)}
                                            offset={0}
                                            position="insideBottom"
                                        />
                                    </YAxis>
                                    {state.maxLimit && (
                                        <ReferenceLine
                                            y={state.maxLimit}
                                            label={translate('INDICATOR_MAX_LIMIT_GRAPH_LEGEND_TEXT')}
                                            stroke="red"
                                            strokeDasharray="3 3"
                                        />
                                    )}
                                    {state.targetLimit && (
                                        <ReferenceLine
                                            y={state.targetLimit}
                                            label={translate('INDICATOR_TARGET_LIMIT_GRAPH_LEGEND_TEXT')}
                                            stroke="green"
                                            strokeDasharray="3 3"
                                        />
                                    )}
                                    {state.minLimit && (
                                        <ReferenceLine
                                            y={state.minLimit}
                                            label={translate('INDICATOR_MIN_LIMIT_GRAPH_LEGEND_TEXT')}
                                            stroke="orange"
                                            strokeDasharray="3 3"
                                        />
                                    )}
                                    <Tooltip />
                                    {localStore.organizationsForGraph.map((organization, index) => (
                                        <Line
                                            dot={false}
                                            connectNulls
                                            key={organization}
                                            type="monotoneX"
                                            dataKey={organization}
                                            strokeWidth={
                                                (context.document.currentDocument.organization?.shortName ||
                                                    context.document.currentDocument.organization.name) === organization
                                                    ? 4
                                                    : 2
                                            }
                                            stroke={context.theming.graphOrganizationColors[index]}
                                        />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
                                {localStore.isGenderDataAvailable && (
                                    <FormControl component="fieldset" sx={{ mb: 2 }}>
                                        <RadioGroup
                                            row
                                            value={localStore.gender}
                                            onChange={localStore.handleGenderChange}
                                        >
                                            <FormControlLabel
                                                value="total"
                                                control={<Radio />}
                                                label={translate('INDICATOR_TOTAL_RADIO_BUTTON')}
                                            />
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio />}
                                                label={translate('INDICATOR_FEMALE_RADIO_BUTTON')}
                                            />
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio />}
                                                label={translate('INDICATOR_MALE_RADIO_BUTTON')}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                                <Box mb={2}>
                                    <Typography component="div">
                                        <Hidden mdDown lgUp>
                                            <IndicatorComparison
                                                origoOrganization={props.indicator?.origoOrganization}
                                                indicator={props.indicator!}
                                                size={'sm'}
                                                genderData={localStore.genderData}
                                            />
                                        </Hidden>
                                        <Hidden lgDown>
                                            <IndicatorComparison
                                                origoOrganization={props.indicator?.origoOrganization}
                                                indicator={props.indicator!}
                                                genderData={localStore.genderData}
                                                size={'md'}
                                            />
                                        </Hidden>
                                        <Hidden mdUp>
                                            <IndicatorComparison
                                                origoOrganization={props.indicator?.origoOrganization}
                                                indicator={props.indicator!}
                                                size={'lg'}
                                                genderData={localStore.genderData}
                                            />
                                        </Hidden>
                                    </Typography>
                                </Box>
                                <Paper variant="outlined" className={classes.paper}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center">
                                                <Typography>{translate('ALARM_LIMITS')}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ShvkTextField
                                                label={translate('INDICATOR_MIN_LIMIT_GRAPH_LEGEND_TEXT')}
                                                variant="outlined"
                                                type="number"
                                                size="small"
                                                value={state.minLimit}
                                                onChange={(event): void => handleChange(event, 'minLimit')}
                                                disabled={props.isTopRowIndicator}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ShvkTextField
                                                label={translate('INDICATOR_MAX_LIMIT_GRAPH_LEGEND_TEXT')}
                                                variant="outlined"
                                                type="number"
                                                size="small"
                                                value={state.maxLimit}
                                                onChange={(event): void => handleChange(event, 'maxLimit')}
                                                disabled={props.isTopRowIndicator}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ShvkTextField
                                                label={translate('INDICATOR_TARGET_LIMIT_GRAPH_LEGEND_TEXT')}
                                                variant="outlined"
                                                type="number"
                                                size="small"
                                                value={state.targetLimit}
                                                onChange={(event): void => handleChange(event, 'targetLimit')}
                                                disabled={props.isTopRowIndicator}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <IconButton
                                                    color="primary"
                                                    onClick={saveLimits}
                                                    size="large"
                                                    disabled={
                                                        props.isTopRowIndicator ||
                                                        context.document.isCurrentDocumentEva ||
                                                        isCurrentDocumentApproved
                                                    }
                                                >
                                                    <Save />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Box flexGrow={1} />
                                <ShvkButton startIcon={<GetApp />} onClick={() => saveIndicatorGraph(state.graphRef)}>
                                    {translate('SAVE_INDICATOR_GRAPH')}
                                </ShvkButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper variant="outlined">
                    <TabContext value={state.activeTab}>
                        <Tabs
                            value={state.activeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}
                            variant="fullWidth"
                        >
                            <Tab
                                label={translate('INDICATOR_DATA_BY_ORGANIZATION_TAB_HEADING')}
                                value="ORGANIZATION_TABLE"
                            />
                            <Tab
                                disabled={props.isTopRowIndicator || context.document.isCurrentDocumentEva}
                                label={translate('DESCRIPTION')}
                                value="DESCRIPTION"
                            />
                        </Tabs>
                        <TabPanel value={'ORGANIZATION_TABLE'}>
                            <TableContainer component={Card} variant="outlined" style={{ overflowX: 'scroll' }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{translate('ORGANIZATION')}</TableCell>
                                            {localStore.tableYears.map((year) => (
                                                <TableCell key={year}>{year}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {localStore.graphData.map((dataItem) => (
                                            <TableRow key={dataItem.name}>
                                                <TableCell>{dataItem.shortName || dataItem.name}</TableCell>
                                                {localStore.tableYears.map((year) => (
                                                    <TableCell key={year}>
                                                        {dataItem.values.find((value) => value.year === year)?.value}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={'DESCRIPTION'}>
                            {props.indicator && (
                                <>
                                    {
                                        <Box display={'flex'} justifyContent={'end'}>
                                            <IconButton
                                                color={'primary'}
                                                disabled={isCurrentDocumentApproved}
                                                onClick={() =>
                                                    setState((state) => ({ ...state, editorOpen: !state.editorOpen }))
                                                }
                                            >
                                                {state.editorOpen ? (
                                                    <MuiTooltip title={translate('CLOSE')}>
                                                        <Close />
                                                    </MuiTooltip>
                                                ) : (
                                                    <>
                                                        <MuiTooltip
                                                            title={translate('EDIT_INDICATOR_DESCRIPTION_TOOLTIP')}
                                                        >
                                                            <Comment />
                                                        </MuiTooltip>
                                                    </>
                                                )}
                                            </IconButton>
                                        </Box>
                                    }
                                    {!state.editorOpen ? (
                                        <Typography
                                            dangerouslySetInnerHTML={{ __html: props.indicator.description || '' }}
                                        />
                                    ) : (
                                        <FroalaIndicatorDescription indicator={props.indicator} />
                                    )}
                                </>
                            )}
                        </TabPanel>
                    </TabContext>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.close}>
                    {translate('CLOSE')}
                </Button>
                <Box flexGrow={1} />
            </DialogActions>
        </StyledDialog>
    );
}

export default observer(IndicatorGraphDialog);
